import { combineReducers } from 'redux'
import authReducer from "./authReducer/index";
import categoryReducers from './menuReducer/categoryReducers';
import MenuReducer from './menuReducer/menuReducer';
import productReducer from './menuReducer/productReducer';
import homeDetailReducer from './homePageReducer/homeDetailReducer';
import menuDetailReducer from './homePageReducer/menuDetailReducer';
import homeSliderReducer from './homePageReducer/homeSliderReducer';
import happyHourMenuReducer from './homePageReducer/happyHourMenuReducer';
import inquiryReducer from './inquiryReducer/inquiryReducer';
import partySpaceDetailReducer from './homePageReducer/partySpaceDetailReducer';
import RestaurantReducer from './homePageReducer/restaurantDetailReducer'; 



const reducer = {
  auth: authReducer,
  category : categoryReducers,
  menu: MenuReducer ,
  product : productReducer,
  homedetails : homeDetailReducer, 
  menudetails : menuDetailReducer,
  homesliderdetails : homeSliderReducer,
  happyhourmenudetails : happyHourMenuReducer,
  inquiry : inquiryReducer,
  partyspacedetail : partySpaceDetailReducer,
  RestaurantReducer: RestaurantReducer
}

const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {

  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}