import { createSlice } from "@reduxjs/toolkit";
import { getAllHappyHourMenuAction , insertUpdateHappyHourMenuAction} from "../../actions/homePageActions/happyHourMenuAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllHappyHourMenu: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateHappyHourMenuDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
}

const HappyHourMenuReducer = createSlice({
    name: "Happy Hour Menu",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetHappyHourMenuDetailsState(state, action) {
            state.insertUpdateHappyHourMenuDetails = INITIAL_STATE.insertUpdateHappyHourMenuDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllHappyHourMenuAction.fulfilled, (state, action) => {
            // console.log("getAllHappyHourMenuAction success", action.payload.data);
            state.getAllHappyHourMenu.data = action.payload.data;
            // console.log(state.getAllHappyHourMenu.data , 'given data');
            state.getAllHappyHourMenu.apiMsg.status = action.meta.requestStatus;
            state.getAllHappyHourMenu.apiMsg.message = "success";
            // console.log(state.getAllHappyHourMenu.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllHappyHourMenuAction.pending, (state, action) => {
            // console.log("getAllHappyHourMenuAction pending", action);
            state.getAllHappyHourMenu.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllHappyHourMenuAction.rejected, (state, action) => {
            // console.log("getAllHappyHourMenuAction rejected", action);
            state.getAllHappyHourMenu.apiMsg.message = action.error.message;
            state.getAllHappyHourMenu.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateHappyHourMenuAction.fulfilled,
            (state, action) => {

                state.insertUpdateHappyHourMenuDetails.data = action.payload.data.data;
                state.insertUpdateHappyHourMenuDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateHappyHourMenuDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data.data, 'reducerdata')

                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateHappyHourMenuAction.pending,
            (state, action) => {
                state.insertUpdateHappyHourMenuDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateHappyHourMenuAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateHappyHourMenuDetails.apiMsg.message = action.error.message;
                state.insertUpdateHappyHourMenuDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );

        // active inactive status case


    }

})

export const { RESET, ResetHappyHourMenuDetailsState } = HappyHourMenuReducer.actions;
export default HappyHourMenuReducer.reducer;