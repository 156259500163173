import { createSlice } from "@reduxjs/toolkit";
import { getAllMenuDetailAction , insertUpdateMenuDetailsAction} from "../../actions/homePageActions/menuDetailAction";
import { toast } from 'react-toastify';


const INITIAL_STATE = {

    getAllMenuDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateMenuDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
       
    },


}

const menuDetailReducer = createSlice({
    name: "MenuDetails",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetMenuDetailsState(state, action) {
            state.insertUpdateMenuDetails = INITIAL_STATE.insertUpdateMenuDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllMenuDetailAction.fulfilled, (state, action) => {
            // console.log("getAllMenuDetailAction success", action.payload.data);
            state.getAllMenuDetails.data = action.payload.data;
            // console.log(state.getAllMenuDetails.data , 'given data');
            state.getAllMenuDetails.apiMsg.status = action.meta.requestStatus;
            state.getAllMenuDetails.apiMsg.message = "success";
            // console.log(state.getAllMenuDetails.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllMenuDetailAction.pending, (state, action) => {
            // console.log("getAllMenuDetailAction pending", action);
            state.getAllMenuDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllMenuDetailAction.rejected, (state, action) => {
            // console.log("getAllMenuDetailAction rejected", action);
            state.getAllMenuDetails.apiMsg.message = action.error.message;
            state.getAllMenuDetails.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateMenuDetailsAction.fulfilled,
            (state, action) => {

                state.insertUpdateMenuDetails.data = action.payload.data;
                state.insertUpdateMenuDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateMenuDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data, 'reducerdata')

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateMenuDetailsAction.pending,
            (state, action) => {
                state.insertUpdateMenuDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateMenuDetailsAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateMenuDetails.apiMsg.message = action.error.message;
                state.insertUpdateMenuDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );




    }

})

export const { RESET, ResetMenuDetailsState } = menuDetailReducer.actions;
export default menuDetailReducer.reducer;