
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { StateCompleted, StateRejected } from './helper/functions/general-func';
import { getAllRestaurantAction } from "./redux-store/actions/homePageActions/restaurantDetailAction";

const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

useEffect(() => {
  if (isLoggedIn) {
    dispatch(getAllRestaurantAction({}));
  }
}, [isLoggedIn]);


  return (
    <>
      {
        isLoggedIn ? <AppRoutes active={isLoggedIn} /> : <AuthRoutes />
      }
    </>
  );
};

export default App;
