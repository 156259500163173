import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, logoutUser } from "../../../API";

export const getAllHomeSliderAction = createAsyncThunk(
  "HomeSlider/getAllHomeSliderAction",
  async (data) => {
    return apiCall("homeslider/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log(response.data, 'action data');
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };

          } else {
            //   Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        //   Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateHomeSliderAction = createAsyncThunk(
  "HomeSlider/insertUpdateHomeSliderAction",
  async (data) => {

    return apiCall("homeslider/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            // console.log("message",message);
            console.log(response,'response data of update')
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const HomeSliderStatusChangeACTION = createAsyncThunk(
  "HomeSlider/HomeSliderStatusChangeACTION",
  async (data) => {
    return apiCall("homeslider/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // Toast.success(response.data.message.message);
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            // Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        // Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

