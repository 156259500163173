import { ReducerStateContant } from "../constants/general-const";
import { getLocalStore } from "../../localStorage";

function StateCompleted(stateObject) {
  if (stateObject.apiMsg && stateObject.apiMsg.status && stateObject.apiMsg.status === ReducerStateContant.fulfilled) {
    return true;
  }
  else {
    return false;
  }
}
function StateNotInitate(stateObject) {
  if (stateObject.apiMsg && stateObject.apiMsg.status == null) {
    return true;
  }
  else if (stateObject.apiMsg == null) {
    return true;
  }
  else {
    return false;
  }
}
function StatePending(stateObject) {
  if (stateObject.apiMsg.status === ReducerStateContant.pending) {
    return true;
  }
  else {
    return false;
  }
}
function StateRejected(stateObject) {
  if (stateObject.apiMsg.status === ReducerStateContant.rejected) {
    return true;
  }
  else {
    return false;
  }
}
function HasRecords(obj) {
  // console.log( "has record length",obj.data.records)
  if (obj && obj.data && obj.data.records && obj.data.records.length) {

    return true;
  }
  else {
    return false;
  }
}
function StateHasData(obj) {
  // console.log( "has record length",obj.data.records)
  if (obj && obj.data) {

    return true;
  }
  else {
    return false;
  }
}
function GetTimeSlot() {

  // configure array for time dropdown
  const timeSlot = [];
  {
    Array.from(Array(24), (time, i) => {
      let displayTime = i <= 9 ? `0${i}` : `${i}`;
      let displayMin = 0;
      Array.from(Array(12), (time, j) => {
        displayMin = parseInt(displayMin);
        if (j > 0 && displayMin <= 55) {
          displayMin = displayMin < 5 ? `0${displayMin + 5}` : `${displayMin + 5}`;
        }
        else {
          displayMin = `00`;
        }


        let displayTimeInfo = `${displayTime}:${displayMin}:00`;
        timeSlot.push(displayTimeInfo);
      })

    })
  }

  return timeSlot;
}
function CheckPermission(permissionName) {
const LOCAL_STORAGE_KEY_USER = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER;
const user = getLocalStore(LOCAL_STORAGE_KEY_USER).user;
if (user.roles && user.roles.length && user.roles.indexOf("Admin") != "-1") {
return true;
}
  else {
// console.log("user.permissions permissionName", permissionName, user.permissions && user.permissions.indexOf(permissionName))
if (user.permissions && user.permissions.indexOf(permissionName) != "-1") {
return true;
}
    else {
return false;
}
  }
}
function GetMappedDataSourceForSelect(dataSoruce, valueKey, labelKey,viewAll) {
  var selectSource = [];
  if (dataSoruce && dataSoruce.length) {
    if(viewAll){
      selectSource.push({value:'all',label:'View All'})
    }
    dataSoruce.map((r) => {
      selectSource.push({
        value: r[valueKey],
        label: r[labelKey]
      })
    })
  }

  return selectSource;
}

export { StateCompleted, StateNotInitate, StatePending, StateRejected, GetTimeSlot, HasRecords,StateHasData, CheckPermission, GetMappedDataSourceForSelect };