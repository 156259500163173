import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navbar,
  ListGroup,
  Dropdown,
  Image,
  DropdownButton,
} from "react-bootstrap";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
// import { isMobile } from 'react-device-detect';

const SidebarMenus = (props) => {
  const isMobile = window.outerWidth < 1024;
  const navigate = useNavigate();
  const location = useLocation();
  const [viewHeight, setViewHeight] = useState(window.innerHeight - 42);
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    navigate("/auth/login");
  }

  const parentMenuId = location.pathname.split("/")[1];

  const getNavLinkClass = (path) => {
    return location.pathname.toLowerCase() === path.toLowerCase() ||
      parentMenuId.toLowerCase() == path.toLowerCase()
      ? "sidebar-item-active"
      : "";
  };

  useEffect(() => {
    setViewHeight(window.innerHeight);
  }, [parentMenuId, props.displaySidebar]);

  const handleMenuClick = (menuId, menuDetails) => {
    if (
      isMobile &&
      menuDetails &&
      menuDetails.SubMenu &&
      menuDetails.SubMenu.length
    ) {
      var tmpViewHeight = window.innerHeight + menuDetails.SubMenu.length * 38;
      setViewHeight(tmpViewHeight);
    }
  };

  const MainMenus = {
    // Bar: "Bar",
    Home: "Home",
    Menu: "Menu",
    Inquiry : "Inquiry",
  };

  const subMenus = {
    mainMenu: {
      Name: "Main Menu",
      Order: 1,
      ParentId: MainMenus.Bar,
      MenuId: "mainMenu",
      HasSubMenu: false,
    },
    Menu: {
      Name: "Menu",
      Order: 1,
      ParentId: MainMenus.Menu,
      MenuId: "Menu",
      HasSubMenu: false,
    },
    studentsNight: {
      Name: "Students Night",
      Order: 2,
      ParentId: MainMenus.Bar,
      MenuId: "StudentsNight",
      HasSubMenu: false,
    },
    category: {
      Name: "Category",
      Order: 2,
      ParentId: MainMenus.Menu,
      MenuId: "category",
      HasSubMenu: false,
    },
    happyHours: {
      Name: "Happy Hours",
      Order: 3,
      ParentId: MainMenus.Bar,
      MenuId: "happyHours",
      HasSubMenu: false,
      
    },
    product: {
      Name: "Products",
      Order: 3,
      ParentId: MainMenus.Menu,
      MenuId: "product",
      HasSubMenu: false,
    },
    homeDetails: {
      Name: "Home Details",
      Order: 1,
      ParentId: MainMenus.Home,
      MenuId: "homeDetails",
      HasSubMenu: false,
    },
    menuDetails: {
      Name: "Menu Details",
      Order: 2,
      ParentId: MainMenus.Home,
      MenuId: "menuDetails",
      HasSubMenu: false,
    },
    homeSlider: {
      Name: "Home Slider",
      Order: 4,
      ParentId: MainMenus.Home,
      MenuId: "homeSlider",
      HasSubMenu: false,
    },
    happyHourMenu: {
      Name: "Happy Hour Menu",
      Order: 3,
      ParentId: MainMenus.Home,
      MenuId: "happyHourMenu",
      HasSubMenu: false,
    },
    PartySpaceDetail: {
      Name: "Party Space Detail",
      Order: 4,
      ParentId: MainMenus.Home,
      MenuId: "PartySpaceDetail",
      HasSubMenu: false,
    },
    Restaurants: {
      Name: "Restaurants",
      Order: 4,
      ParentId: MainMenus.Home,
      MenuId: "Restaurants",
      HasSubMenu: false,
    },
  };

  const menuList = [
    // {
    //   Name: "Bar",
    //   Order: 1,
    //   ParentId: null,
    //   MenuId: MainMenus.Bar,
    //   HasSubMenu: true,
    //   DisplayInSideMenu: true,
    //   SubMenu: [subMenus.mainMenu, subMenus.studentsNight, subMenus.happyHours],
    // },
    {
      Name: "Home",
      Order:2,
      ParentId: null,
      MenuId: MainMenus.Home,
      HasSubMenu: false,
      DisplayInSideMenu: true,
       SubMenu: [subMenus.homeDetails, subMenus.menuDetails,subMenus.happyHourMenu,subMenus.homeSlider,subMenus.PartySpaceDetail, subMenus.Restaurants],
    },
    {
      Name: "Menu",
      Order: 3,
      ParentId: null,
      MenuId: MainMenus.Menu,
      HasSubMenu: true,
      DisplayInSideMenu: true,
      SubMenu: [subMenus.Menu, subMenus.category, subMenus.product],
    },
    {
      Name: "Inquiry",
      Order: 4,
      ParentId: null,
      MenuId: MainMenus.Inquiry,
      HasSubMenu: false,
      DisplayInSideMenu: true,
      // SubMenu: [subMenus.Menu, subMenus.category, subMenus.product],
    },
  
  ];

  return isLoggedIn ? (
    <div className="menu-sidebar p-0" style={{ minHeight: viewHeight }}>
      <Navbar.Brand className="d-block text-center sidebar-logo mb-2">
        <Image src={require("../../images/logo-akshaya.png")} />
      </Navbar.Brand>
      {/* <Button variant="light" size="sm" className="d-block m-auto text-uppercase bg-white mb-2 btn-quick-add btn-gpnt"><FaPlus className="gpnt-icon" /> New</Button> */}
      <ListGroup className="rounded-0 sidebar-links bg-transparent">
        {menuList.length ? (
          menuList.map((menu, i) => (
            <div key={"menu-main-list-" + i}>
              {menu.DisplayInSideMenu && (
                <div key={"menu-list-" + i}>
                  {menu.SubMenu && menu.SubMenu.length ? (
                    <DropdownButton
                      as="li"
                      className={
                        "px2 py1  list-group-item gpnt-sidemnu parent-sidemenu " +
                        getNavLinkClass(menu.MenuId)
                      }
                      key={"sidemenu-" + menu.MenuId}
                      id={`dropdown-button-drop-${menu.MenuId}`}
                      drop="end"
                      onClick={handleMenuClick.bind(
                        menu.MenuId,
                        "/" +
                          menu.SubMenu[0].ParentId +
                          "/" +
                          menu.SubMenu[0].MenuId,
                        menu
                      )}
                      //onMouseOver={handleMenuOnMouseEnter}
                      menuid={menu.MenuId}
                      //onMouseLeave={handleMenuOnMouseLeave}
                      title={menu.Name}
                    >
                      {menu.SubMenu.map((subMenu, i) => (
                        <>
                          <Dropdown.Item
                            as={NavLink}
                            //to={"/" + subMenu.ParentId + "/" + subMenu.MenuId}
                            to={`/${subMenu.ParentId}/${subMenu.MenuId}${
                              subMenu.HasSubMenu
                                ? `/${subMenu.SubMenu[0].MenuId}`
                                : ""
                            }`}
                            key={"subMemnu-" + subMenu.MenuId + i}
                          >
                            {subMenu.Name}
                          </Dropdown.Item>
                        </>
                      ))}
                    </DropdownButton>
                  ) : (
                    <ListGroup.Item
                      as="li"
                      className={
                        "px2 py1 gpnt-sidemnu " +
                        getNavLinkClass("/" + menu.MenuId)
                      }
                    >
                      <NavLink
                        to={"/" + menu.MenuId}
                        key={"sidemenu-" + menu.MenuId + i}
                      >
                        {menu.Name}
                      </NavLink>
                    </ListGroup.Item>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </ListGroup>
    </div>
  ) : (
    <></>
  );
};

export default SidebarMenus;
