import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
// import Header from "./shared/Header";
// import SideMenu from "./shared/SideMenu";
import { getLocalStore, setLocalStore } from "../localStorage/index";
import { logoutACTION } from "../redux-store/actions/authActions";
import { CheckPermission } from "../helper/functions/general-func";
import {
    Container,
    Form,
    Navbar,
    Button,
    Overlay,
    Popover,
    Card,
    Figure,
    Badge,
    ListGroup
} from "react-bootstrap";
import SidebarMenus from "./shared/SidebarMenus";
import { getAllRestaurantAction } from "../redux-store/actions/homePageActions/restaurantDetailAction.js";
import { StateCompleted } from "../helper/functions/general-func";
import { SELECTRESTAURANTaction } from "../redux-store/reducers/homePageReducer/restaurantDetailReducer.js";

function Layout({ name }) {
    const isMobile = window.outerWidth < 1024;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const [restaurantData, setRestaurantData] = useState([]);

    const stateRestaurant = useSelector((state) => state.RestaurantReducer);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const displayName = user && user.user ? (user.user.userName) : "";

    const locationPathInfo = location.pathname.split("/");
    let parentMenuId = null;
    let childMenuId = null;
    let childSubMenuId = null;

    const [displaySidebar, SetSidebar] = useState(isMobile ? "" : "sidebar-visible");
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    if (locationPathInfo.length) {
        parentMenuId = locationPathInfo[1];
    }
    if (locationPathInfo.length > 2) {
        childMenuId = location.pathname.split("/")[2];
    }
    if (locationPathInfo.length > 3) {
        childSubMenuId = location.pathname.split("/")[3];
    }

    // hide/show sidebar
    useEffect(() => {
        SetSidebar(isMobile ? "" : "sidebar-visible")
    }, [parentMenuId]);

    useEffect(() => {
        if (isMobile) {
            SetSidebar("");
        }
    }, [location]);


    function ManageSidebar() {
        SetSidebar(displaySidebar === "" ? "sidebar-visible" : "");
    }

    // toggle user settings dropdown

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    if (!isLoggedIn) {
        navigate('/auth/login')
    }

    const LogoutUser = () => {
        dispatch(logoutACTION())
            .then((d) => {
                navigate('/auth/login');
            })
            .catch(() => {

            });

    }

    const onLastSelect = (restaurantId) => {
        const updatedRestaurants = stateRestaurant.Restaurants.data.map((item) => {
          const isSelected = item.id === restaurantId;
          if (isSelected) {
            localStorage.setItem("last_rest", JSON.stringify({ lastRestaurant: item }));
          }
          return {
            ...item,
            selected: isSelected,
          };
        });
      
        dispatch(SELECTRESTAURANTaction(updatedRestaurants));
      };
      

    return (
        <div id="layout-wraper" className={"app-main " + displaySidebar}>
            <SidebarMenus displaySidebar={displaySidebar} />
            <div className="min-vh-100 main-content p-0">
                <Navbar className="main-header">
                    <i className="fa-solid fa-bars ms-2 me-4 sidebar-ctrl-i" onClick={() => ManageSidebar()}></i>
                    <Form className="d-flex w-100 justify-content-end align-items-center">

                        <div className="position-relative">
                            {/* <Form.Select
                                className="d-flex w-260 header-select me-3"
                                aria-label="Select Restaurant"
                                value={selectedId}
                                onChange={handleSelectChange}
                            >
                                {restaurantData.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Select> */}

                            <Form.Select
                                className={`d-flex w-260 header-select me-3`}
                                aria-label="Select Restaurant"
                                value={
                                    localStorage.getItem("last_rest")
                                        ? JSON.parse(localStorage.getItem("last_rest")).lastRestaurant.id
                                        : stateRestaurant.Restaurants.data && stateRestaurant.Restaurants.data.length
                                            ? stateRestaurant.Restaurants.data[0].id
                                            : ""
                                }
                                onChange={(e) => {
                                    onLastSelect(e.target.value);
                                }}
                            >
                                {stateRestaurant.Restaurants.data &&
                                    stateRestaurant.Restaurants.data.length &&
                                    stateRestaurant.Restaurants.data.map((item, index) => {
                                        return (
                                            <option key={index} id={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                            </Form.Select>

                        </div>

                        <div className="pe-3 position-relative" ref={ref}>
                            <i className="fa-solid fa-circle-user header-links header-icon" onClick={handleClick}></i>
                            <Overlay
                                show={show}
                                target={target}
                                placement="bottom"
                                container={ref}
                                onHide={() => setShow(!show)}
                                rootClose={true}
                            >
                                <Popover id="popover-contained">
                                    <Popover.Body>
                                        <Card className="text-center">

                                            <Card.Body>
                                                <Card.Title>
                                                    <Figure>

                                                        <Figure.Caption className="text-uppercase">
                                                            {displayName.charAt(0)}
                                                        </Figure.Caption>
                                                    </Figure>
                                                </Card.Title>
                                                <Card.Text className="mb-0 text-capitalize">
                                                    {displayName}
                                                </Card.Text>

                                                {
                                                    CheckPermission() ?
                                                        <Card.Link className="cursor-pointer" onClick={() => { setShow(!show); navigate("/ownerSettings/HeadofficeUsers") }}>Manage Your Account</Card.Link>
                                                        :
                                                        <></>
                                                }

                                            </Card.Body>
                                            <Card.Footer className="text-muted bg-transparent"><Button variant="primary" onClick={() => LogoutUser()} className="btn-sm w-100">Sign Out</Button></Card.Footer>
                                        </Card>
                                        {/* <ListGroup
                        className="rounded-0 sidebar-links bg-transparent"
                      >
                        <NavLink to={"/profile"} >
                          Profile
                        </NavLink>
                        <NavLink to={"/auth/login"} onClick={() => LogoutUser()} >
                          Logout
                        </NavLink>
                      </ListGroup> */}
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                        </div>
                    </Form>

                </Navbar>


                <div className="clearfix"></div>
                <Container fluid >
                    <Outlet />
                </Container>
            </div>
        </div>


    )
}

export default Layout