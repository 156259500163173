import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
// import * as Toast from "../../../component/Toast";


export const getAllRestaurantAction = createAsyncThunk(
    "Restaurant/getAllRestaurantAction",
    async (data) => {
      if (!localStorage.getItem("usersInfo")) {
        return Promise.reject(false);
      }
  
      return apiCall("restaurant/getall", "POST", data, false)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (!response.data.hasError) {
              let lastRestaurantId = null;
              if (localStorage.getItem("last_rest") !== null) {
                const lastRestData = JSON.parse(localStorage.getItem("last_rest"));
                lastRestaurantId = lastRestData.lastRestaurant.id;
              }
  
              return {
                ...response.data,
                data: {
                  ...response.data.data,
                  records: response.data.data.records.map((item, index) => {
                    const isSelected = lastRestaurantId
                      ? item.id === lastRestaurantId
                      : index === 0;
                    if (isSelected) {
                      localStorage.setItem("last_rest", JSON.stringify({ lastRestaurant: item }));
                    }
                    return {
                      ...item,
                      selected: isSelected,
                    };
                  }),
                },
              };
            } else {
              return Promise.reject({ ...response.data.message });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({ ...error });
        });
    }
  );
  

  
// export const getAllRestaurantAction = createAsyncThunk(
//     "Restaurant/getAllRestaurantAction",

//     async (data) => {
//         let d = {
//             filters: {
//             },
//             page: {
//                 pageNumber: 0,
//                 pageSize: 0
//             },
//             deviceType: 0
//         };

//         return apiCall("restaurant/getall", "POST", d, false)
//             .then((response) => {
//                 if (response.status === 200) {
//                     if (!response.data.hasError) {
//                         // console.log("api call data", response.data);
//                         return {
//                             ...response.data,
//                             data: {
//                                 ...response.data.data,
//                             },
//                         };
//                     } else {
//                         // Toast.error(response.data.message.message);
//                         // return Promise.reject({
//                         //   ...response.data.message,
//                         // });
//                     }
//                 }
//             })
//             .catch((error) => {
//                 // Toast.error(error);
//                 return Promise.reject({
//                     ...error,
//                 });
//             });
//     }
// );


//insertUpdate


export const insertUpdateRestaurantAction = createAsyncThunk(
    "Restaurant/insertUpdateRestaurantAction",
    async (data) => {

        return apiCall("restaurant/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        // console.log(response.data,'response data of update')
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const restaurantStatusChangeACTION = createAsyncThunk(
    "Restaurant/reataurantStatusChangeACTION",
    async (data) => {
        return apiCall("restaurant/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        // Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                // Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);