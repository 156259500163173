import React, { useState, useRef, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { apiUploadCall } from "../../../API";
import { ErrorMessage } from "@hookform/error-message";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  StateCompleted,
  StateRejected,
} from "../../../helper/functions/general-func";
import {
  getAllPartySpaceDetailAction,
  insertUpdatePartySpaceDetailAction,
} from "../../../redux-store/actions/homePageActions/partySpaceDetailAction";

export default function PartySpaceDetail() {
  const [currentData, setCurrentData] = useState();

  const statePartySpaceDetails = useSelector((state) => state.partyspacedetail);

  const stateRestaurant = useSelector((state) => state.RestaurantReducer);

  const selectedRestaurantId = stateRestaurant.Restaurants.data && stateRestaurant.Restaurants.data.length && stateRestaurant.Restaurants.data.find(
    (item) => item.selected === true
  )?.id;

  useEffect(() => {
    if (selectedRestaurantId) {
      dispatch(getAllPartySpaceDetailAction({ restaurantID: selectedRestaurantId }));
    }
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (StateCompleted(statePartySpaceDetails.getAllPartySpaceDetail)) {
      if(statePartySpaceDetails.getAllPartySpaceDetail.data)
        {
          setCurrentData(statePartySpaceDetails.getAllPartySpaceDetail.data);
        }
    }
    else{
      setCurrentData({});
      reset();
    }
  }, [statePartySpaceDetails.getAllPartySpaceDetail]);

  console.log(currentData, "party space data");

  useEffect(() => {
    if (currentData) {
      setPartySpaceImagesDefaults(currentData);
      reset(currentData);
    }
  }, [currentData]);

  const setPartySpaceImagesDefaults = (data) => {
    setBanquetHallImage(
      data.hallImage ===
        "https://cyhvilymsa.cloudimg.io/__rms-stage__/gallery/c6eb53c3-6430-4f8a-80cb-f8385015869e/"
        ? null
        : data.hallImage
          ? { fileKey: data.hallImage, fileUrl: data.hallImage }
          : null
    );
    setPrivateRoom1Image(
      data.room1Image ===
        "https://cyhvilymsa.cloudimg.io/__rms-stage__/gallery/c6eb53c3-6430-4f8a-80cb-f8385015869e/"
        ? null
        : data.room1Image
          ? {
            fileKey: data.room1Image,
            fileUrl: data.room1Image,
          }
          : null
    );
    setPrivateRoom2Image(
      data.room2Image ===
        "https://cyhvilymsa.cloudimg.io/__rms-stage__/gallery/c6eb53c3-6430-4f8a-80cb-f8385015869e/"
        ? null
        : data.room2Image
          ? {
            fileKey: data.room2Image,
            fileUrl: data.room2Image,
          }
          : null
    );
    setoutdoorCateringImage(
      data.outdoorImage ===
        "https://cyhvilymsa.cloudimg.io/__rms-stage__/gallery/c6eb53c3-6430-4f8a-80cb-f8385015869e/"
        ? null
        : data.outdoorImage
          ? { fileKey: data.outdoorImage, fileUrl: data.outdoorImage }
          : null
    );
  };

  const [banquetHallImage, setBanquetHallImage] = useState();
  const [privateRoom1Image, setPrivateRoom1Image] = useState();
  const [privateRoom2Image, setPrivateRoom2Image] = useState();
  const [outdoorCateringImage, setoutdoorCateringImage] = useState();

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  const fileuploadRef1 = useRef();
  const fileuploadRef2 = useRef();
  const fileuploadRef3 = useRef();
  const fileuploadRef4 = useRef();

  const handleFileInputOpen = (inputRef) => {
    inputRef.current.click();
  };

  const [fileUrl, setFileUrl] = useState(false);
  const [fileKey, setFileKey] = useState(false);

  const [loading, setLoading] = useState({
    banquetImage: false,
    room1Image: false,
    room2Image: false,
    outdoorImage: false,
  });

  const [saveloading, setSaveLoading] = useState(false);

  const handleImageChange = (e, num) => {
    const form = new FormData();
    form.append("model", e.target.files[0]);
    // setLoading({banquetImage: true,banquetImage: true});
    setLoading((prevLoading) => ({
      ...prevLoading,
      [num === 1 && "banquetImage"]: true,
      [num === 2 && "room1Image"]: true,
      [num === 3 && "room2Image"]: true,
      [num === 4 && "outdoorImage"]: true,
    }));

    apiUploadCall(`files/addpicinstorage`, form, () => { })
      .then((res) => {
        setFileKey(res.data.fileKey);
        setFileUrl(res.data.fileUrl);
        if (num === 1) {
          setBanquetHallImage(res.data);
          // setLoading({banquetImage: false});
        } else if (num === 2) {
          setPrivateRoom1Image(res.data);
          // setLoading({banquetImage: false});
        } else if (num === 3) {
          setPrivateRoom2Image(res.data);
        } else if (num === 4) {
          setoutdoorCateringImage(res.data);
        }

        // setLoading(false);
        setLoading((prevLoading) => ({
          ...prevLoading,
          banquetImage: num === 1 ? false : prevLoading.banquetImage,
          room1Image: num === 2 ? false : prevLoading.room1Image,
          room2Image: num === 3 ? false : prevLoading.room2Image,
          outdoorImage: num === 4 ? false : prevLoading.outdoorImage,
        }));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues: currentData,
    mode: "all",
  });

  const handleRemoveImage = (num) => {
    if (num === 1) {
      setBanquetHallImage(null);
      if (fileuploadRef1 && fileuploadRef1.current) {
        fileuploadRef1.current.style.display = "block";
      }
    }
    if (num === 2) {
      setPrivateRoom1Image(null);
      if (fileuploadRef2 && fileuploadRef2.current) {
        fileuploadRef2.current.style.display = "block";
      }
    }
    if (num === 3) {
      setPrivateRoom2Image(null);
      if (fileuploadRef3 && fileuploadRef3.current) {
        fileuploadRef3.current.style.display = "block";
      }
    }
    if (num === 4) {
      setoutdoorCateringImage(null);
      if (fileuploadRef4 && fileuploadRef4.current) {
        fileuploadRef4.current.style.display = "block";
      }
    }
  };

  function onSubmit(data) {
    setSaveLoading(true);

    var reqData = {
      ...data,
      restaurantID: selectedRestaurantId,
      hallImage: banquetHallImage ? banquetHallImage.fileKey : "",
      room1Image: privateRoom1Image ? privateRoom1Image.fileKey : "",
      room2Image: privateRoom2Image ? privateRoom2Image.fileKey : "",
      outdoorImage: outdoorCateringImage ? outdoorCateringImage.fileKey : "",
    };

    dispatch(insertUpdatePartySpaceDetailAction(reqData))
      .then(() => {
        setSaveLoading(false);
      })
      .catch((error) => {
        setSaveLoading(false);
      });

    console.log(errors);
  }

  return (
    <div className="container-fluid">
      <div>
        <div className="grid-box headoffice-set-box">
          <div>
            {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
            <Form>
              <div className="mt-3 row">
                <div className="mb-2 col-xl-3 col-sm-12 setcardfix ">
                  <div className="h-100 gpnt-pos-param-card card">
                    <div className="gpnt-pos-param-header card-header">
                      Banquet Hall Details
                    </div>
                    <div className="gpnt-promo-info-body card-body">
                      <div className="">
                        <div className="row">
                          <div className="">
                            <div className="gpnt-form-group row">
                              <label className="gpnt-form-label form-label col-form-label col-sm-3">
                                Title Text
                                <span className="req-star">*</span>
                              </label>
                              <div className="col-sm-9 ">
                                <input
                                  type="text"
                                  className="gnpt-form-control form-control"
                                  {...register("hallText", {
                                    required: "Title text is required.",
                                  })}
                                />
                                {errors.hallText?.type === "required" && (
                                  <Form.Label className="error-message">
                                    {errors.hallText.message}
                                  </Form.Label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center h-243">
                          <div className=" h-100 rounded-sm border w-100 gpnt-pos-param-card card h-243">
                            {!loading.banquetImage
                              ? banquetHallImage && (
                                <div className="gpnt-pos-param-header card-header d-flex justify-content-between align-items-center w-100">
                                  <span className=""></span>

                                  <div className="">
                                    <div className="d-flex justify-content-end align-items-end gap-2">
                                      {/* <button
                                          type="button"
                                          className="btn-sm btn btn-primary float-end"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef8)
                                          }
                                        > */}
                                      <span>
                                        <i
                                          role="button"
                                          className="fa fa-edit text-primary fs-icon pe-auto"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef1)
                                          }
                                        ></i>
                                      </span>
                                      {/* </button> */}
                                      <div className="img-delete">
                                        {/* <button
                                            type="button"
                                            className="btn btn-sm btn-danger "
                                            onClick={() => handleRemoveImage(8)}
                                          > */}
                                        <span>
                                          <i
                                            className="fa fa-trash text-danger fs-icon pe-auto"
                                            role="button"
                                            onClick={() =>
                                              handleRemoveImage(1)
                                            }
                                          ></i>
                                        </span>
                                        {/* </button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                              : null}
                            {!loading.banquetImage && !banquetHallImage && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <div
                                  className="uploaddiv"
                                  ref={fileuploadRef1}
                                ></div>

                                <div
                                  role="button"
                                  onClick={() => handleFileInputOpen(inputRef1)}
                                >
                                  <h5 className="text-center">
                                    <i className="fa-solid fa-cloud-arrow-up uploadicon"></i>
                                    <br />
                                    <span>Click here to Upload Img</span>
                                  </h5>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              ref={inputRef1}
                              accept="image/*"
                              onChange={(e) => {
                                if (fileuploadRef1 && fileuploadRef1.current) {
                                  fileuploadRef1.current.style.display = "none";
                                }
                                handleImageChange(e, 1);
                              }}
                              className="fileinput d-none"
                            ></input>

                            {loading.banquetImage ? (
                              <div className="d-flex h-100 justify-content-center align-items-center gap-2">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              banquetHallImage && (
                                <div className="h-210">
                                  <img
                                    src={banquetHallImage.fileUrl}
                                    className="previewimg"
                                    alt="Uploaded Preview"
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2 col-xl-3 col-sm-12 setcardfix ">
                  <div className="h-100 gpnt-pos-param-card card">
                    <div className="gpnt-pos-param-header card-header">
                      Private Room 1 Details
                    </div>
                    <div className="gpnt-promo-info-body card-body">
                      <div className="">
                        <div className="row">
                          <div className="">
                            <div className="gpnt-form-group row">
                              <label className="gpnt-form-label form-label col-form-label col-sm-3">
                                Title Text
                                <span className="req-star">*</span>
                              </label>
                              <div className="col-sm-9 ">
                                <input
                                  type="text"
                                  className="gnpt-form-control form-control"
                                  {...register("room1Text", {
                                    required: "Title text is required.",
                                  })}
                                />
                                {errors.room1Text?.type === "required" && (
                                  <Form.Label className="error-message">
                                    {errors.room1Text.message}
                                  </Form.Label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center h-243">
                          <div className=" h-100 rounded-sm border w-100 gpnt-pos-param-card card h-243">
                            {!loading.room1Image
                              ? privateRoom1Image && (
                                <div className="gpnt-pos-param-header card-header d-flex justify-content-between align-items-center w-100">
                                  <span className=""></span>

                                  <div className="">
                                    <div className="d-flex justify-content-end align-items-end gap-2">
                                      {/* <button
                                          type="button"
                                          className="btn-sm btn btn-primary float-end"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef8)
                                          }
                                        > */}
                                      <span>
                                        <i
                                          role="button"
                                          className="fa fa-edit text-primary fs-icon pe-auto"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef2)
                                          }
                                        ></i>
                                      </span>
                                      {/* </button> */}
                                      <div className="img-delete">
                                        {/* <button
                                            type="button"
                                            className="btn btn-sm btn-danger "
                                            onClick={() => handleRemoveImage(8)}
                                          > */}
                                        <span>
                                          <i
                                            className="fa fa-trash text-danger fs-icon pe-auto"
                                            role="button"
                                            onClick={() =>
                                              handleRemoveImage(2)
                                            }
                                          ></i>
                                        </span>
                                        {/* </button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                              : null}
                            {!loading.room1Image && !privateRoom1Image && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <div
                                  className="uploaddiv"
                                  ref={fileuploadRef2}
                                ></div>

                                <div
                                  role="button"
                                  onClick={() => handleFileInputOpen(inputRef2)}
                                >
                                  <h5 className="text-center">
                                    <i className="fa-solid fa-cloud-arrow-up uploadicon"></i>
                                    <br />
                                    <span>Click here to Upload Img</span>
                                  </h5>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              ref={inputRef2}
                              accept="image/*"
                              onChange={(e) => {
                                if (fileuploadRef2 && fileuploadRef2.current) {
                                  fileuploadRef2.current.style.display = "none";
                                }
                                handleImageChange(e, 2);
                              }}
                              className="fileinput d-none"
                            ></input>
                            {loading.room1Image ? (
                              <div className="d-flex h-100 justify-content-center align-items-center gap-2">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              privateRoom1Image && (
                                <div className="h-210">
                                  <img
                                    src={privateRoom1Image.fileUrl}
                                    className="previewimg"
                                    alt="Uploaded Preview"
                                  />
                                </div>

                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2 col-xl-3 col-sm-12 setcardfix ">
                  <div className="h-100 gpnt-pos-param-card card">
                    <div className="gpnt-pos-param-header card-header">
                      Private Room 2 Details
                    </div>
                    <div className="gpnt-promo-info-body card-body">
                      <div className="">
                        <div className="row">
                          <div className="">
                            <div className="gpnt-form-group row">
                              <label className="gpnt-form-label form-label col-form-label col-sm-3">
                                Title Text
                                <span className="req-star">*</span>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="gnpt-form-control form-control"
                                  {...register("room2Text", {
                                    required: "Title text is required.",
                                  })}
                                />
                                {errors.room2Text?.type === "required" && (
                                  <Form.Label className="error-message">
                                    {errors.room2Text.message}
                                  </Form.Label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center h-243">
                          <div className=" h-100 rounded-sm border w-100 gpnt-pos-param-card card h-243">
                            {!loading.room2Image
                              ? privateRoom2Image && (
                                <div className="gpnt-pos-param-header card-header d-flex justify-content-between align-items-center w-100">
                                  <span className=""></span>

                                  <div className="">
                                    <div className="d-flex justify-content-end align-items-end gap-2">
                                      {/* <button
                                          type="button"
                                          className="btn-sm btn btn-primary float-end"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef8)
                                          }
                                        > */}
                                      <span>
                                        <i
                                          role="button"
                                          className="fa fa-edit text-primary fs-icon pe-auto"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef3)
                                          }
                                        ></i>
                                      </span>
                                      {/* </button> */}
                                      <div className="img-delete">
                                        {/* <button
                                            type="button"
                                            className="btn btn-sm btn-danger "
                                            onClick={() => handleRemoveImage(8)}
                                          > */}
                                        <span>
                                          <i
                                            className="fa fa-trash text-danger fs-icon pe-auto"
                                            role="button"
                                            onClick={() =>
                                              handleRemoveImage(3)
                                            }
                                          ></i>
                                        </span>
                                        {/* </button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                              : null}
                            {!loading.room2Image && !privateRoom2Image && (
                              <div className="d-flex h-100 justify-content-center align-items-center">
                                <div className="uploaddiv" ref={fileuploadRef3}></div>

                                <div
                                  role="button"
                                  onClick={() => handleFileInputOpen(inputRef3)}
                                >
                                  <h5 className="text-center">
                                    <i className="fa-solid fa-cloud-arrow-up uploadicon"></i>
                                    <br />
                                    <span>Click here to Upload Img</span>
                                  </h5>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              ref={inputRef3}
                              accept="image/*"
                              onChange={(e) => {
                                if (fileuploadRef3 && fileuploadRef3.current) {
                                  fileuploadRef3.current.style.display = "none";
                                }
                                handleImageChange(e, 3);
                              }}
                              className="fileinput d-none"
                            ></input>

                            {loading.room2Image ? (
                              <div className="d-flex h-100 justify-content-center align-items-center gap-2">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              privateRoom2Image && (
                                <div className="h-210">
                                  <img
                                    src={privateRoom2Image.fileUrl}
                                    className="previewimg"
                                    alt="Uploaded Preview"
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2 col-xl-3 col-sm-12 setcardfix ">
                  <div className="h-100 gpnt-pos-param-card card">
                    <div className="gpnt-pos-param-header card-header">
                      Outdoor Catering Details
                    </div>
                    <div className="gpnt-promo-info-body card-body">
                      <div className="">
                        <div className="row">
                          <div className="">
                            <div className="gpnt-form-group row">
                              <label className="gpnt-form-label form-label col-form-label col-sm-3">
                                Title Text
                                <span className="req-star">*</span>
                              </label>
                              <div className="col-sm-9 ">
                                <input
                                  type="text"
                                  className="gnpt-form-control form-control"
                                  {...register("outdoorText", {
                                    required: "Title text is required.",
                                  })}
                                />
                                {errors.outdoorText?.type === "required" && (
                                  <Form.Label className="error-message">
                                    {errors.outdoorText.message}
                                  </Form.Label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center h-243">
                          <div className=" h-100 rounded-sm border w-100 gpnt-pos-param-card card h-243 ">
                            {!loading.outdoorImage
                              ? outdoorCateringImage && (
                                <div className="gpnt-pos-param-header card-header d-flex justify-content-between align-items-center w-100">
                                  <span className=""></span>

                                  <div className="">
                                    <div className="d-flex justify-content-end align-items-end gap-2">
                                      {/* <button
                                          type="button"
                                          className="btn-sm btn btn-primary float-end"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef8)
                                          }
                                        > */}
                                      <span>
                                        <i
                                          role="button"
                                          className="fa fa-edit text-primary fs-icon pe-auto"
                                          onClick={() =>
                                            handleFileInputOpen(inputRef4)
                                          }
                                        ></i>
                                      </span>
                                      {/* </button> */}
                                      <div className="img-delete">
                                        {/* <button
                                            type="button"
                                            className="btn btn-sm btn-danger "
                                            onClick={() => handleRemoveImage(8)}
                                          > */}
                                        <span>
                                          <i
                                            className="fa fa-trash text-danger fs-icon pe-auto"
                                            role="button"
                                            onClick={() =>
                                              handleRemoveImage(4)
                                            }
                                          ></i>
                                        </span>
                                        {/* </button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                              : null}
                            {!loading.outdoorImage &&
                              !outdoorCateringImage && (
                                <div className="d-flex h-100 justify-content-center align-items-center">
                                  <div className="uploaddiv" ref={fileuploadRef4}></div>

                                  <div
                                    role="button"
                                    onClick={() =>
                                      handleFileInputOpen(inputRef4)
                                    }
                                  >
                                    <h5 className="text-center">
                                      <i className="fa-solid fa-cloud-arrow-up uploadicon"></i>
                                      <br />
                                      <span>Click here to Upload Img</span>
                                    </h5>
                                  </div>
                                </div>
                              )}
                            <input
                              type="file"
                              ref={inputRef4}
                              accept="image/*"
                              onChange={(e) => {
                                if (fileuploadRef4 && fileuploadRef4.current) {
                                  fileuploadRef4.current.style.display = "none";
                                }
                                handleImageChange(e, 4);
                              }}
                              className="fileinput d-none"
                            ></input>

                            {loading.outdoorImage ? (
                              <div className="d-flex h-100 justify-content-center align-items-center gap-2">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              outdoorCateringImage && (
                                <div className="h-210">
                                  <img
                                    src={outdoorCateringImage.fileUrl}
                                    className="previewimg"
                                    alt="Uploaded Preview"
                                  />
                                </div>

                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="canvas-footer">
                <button
                  type="button"
                  className="me-2 btn-card-footer btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={saveloading}
                >
                  {saveloading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> Save</span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
