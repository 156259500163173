import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, logoutUser } from "../../../API";


export const loginAction = createAsyncThunk(
    "auth/login",
    async (data) => {
        return apiCall("masteradmin/login", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem(
                        "usersInfo",
                        JSON.stringify(response.data.data)
                    );
                    return {
                        ...response.data,
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const logoutACTION = createAsyncThunk(
    'auth/logout',
    async (data) => {
        return logoutUser()
            .then((response) => {

                return response;

            })
    }
)

export const authorizedAccessACTION = createAsyncThunk(
    'auth/authorizedaccess',
    async (data) => {
        return apiCall("user/loginwithpermission", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
)
