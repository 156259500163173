import { createSlice } from "@reduxjs/toolkit";
import { getAllCategoriesAction, insertUpdateCategoryAction, categoriesStatusChangeACTION } from "../../actions/menuActions/categoriesActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllCategory: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateCategoryDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactiveCategoryDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    // deleteCategoryDetails: {
    //     data: {},
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // }

}

const categoryReducer = createSlice({
    name: "Category",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCategoryDetailsState(state, action) {
            state.insertUpdateCategoryDetails = INITIAL_STATE.insertUpdateCategoryDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllCategoriesAction.fulfilled, (state, action) => {
            // console.log("getAllCategoriesAction success", action.payload.data);
            state.getAllCategory.data = action.payload.data.records;
            // console.log(state.getAllCategory.data , 'given data');
            state.getAllCategory.apiMsg.status = action.meta.requestStatus;
            state.getAllCategory.apiMsg.message = "success";
            // console.log(state.getAllCategory.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllCategoriesAction.pending, (state, action) => {
            // console.log("getAllCategoriesAction pending", action);
            state.getAllCategory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCategoriesAction.rejected, (state, action) => {
            // console.log("getAllCategoriesAction rejected", action);
            state.getAllCategory.apiMsg.message = action.error.message;
            state.getAllCategory.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateCategoryAction.fulfilled,
            (state, action) => {

                state.insertUpdateCategoryDetails.data = action.payload.data.data;
                state.insertUpdateCategoryDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateCategoryDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data.data, 'reducerdata')

                if (!action.payload.newRecord) {
                    // let updatedRecord = state.getAllCategory.data.records.map(x => {
                    //     if (x.id == action.payload.data.data.id) {
                    //         console.log(action.payload.data, 'updated Data');
                    //         return action.payload.data.data;
                    //     }
                    //     else {
                    //         return x;
                    //     }
                    // })

                    // state.getAllCategory.data.records = updatedRecord;
                }
                else {
                    if (state.getAllCategory.data.records) {
                        state.getAllCategory.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        // state.getAllCategory.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateCategoryAction.pending,
            (state, action) => {
                state.insertUpdateCategoryDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateCategoryAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateCategoryDetails.apiMsg.message = action.error.message;
                state.insertUpdateCategoryDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );


        // active inactive status case


        builder.addCase(categoriesStatusChangeACTION.fulfilled, (state, action) => {
            // console.log(action.payload, 'reducer payload');
            // state.activeInactiveCategoryDetails.data.records = state.activeInactiveCategoryDetails.data.records.map((item) => {

            //     return {
            //         ...item,
            //         active: action.payload.id === item.id ? !item.active : item.active
            //     }
            // })

            state.activeInactiveCategoryDetails.data = action.payload.data;
            state.activeInactiveCategoryDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveCategoryDetails.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });

        });

        builder.addCase(categoriesStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveCategoryDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(categoriesStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveCategoryDetails.apiMsg.message = action.error.message;
            state.activeInactiveCategoryDetails.apiMsg.status = action.meta.requestStatus;
        });



        // delete changestatus  change

        // builder.addCase(categoriesDeleteACTION.fulfilled, (state, action) => {

        //     // state.PosPaymentSettings.data.records = state.PosPaymentSettings.data.records.filter((item, index) => item.id !== action.payload.data.id)

        //     state.activeInactiveCategoryDetails.data = action.payload.data;
        //     state.deleteCategoryDetails.apiMsg.status = action.meta.requestStatus;
        //     state.deleteCategoryDetails.apiMsg.message = action.payload.message.message;
        // });
        // builder.addCase(categoriesDeleteACTION.pending, (state, action) => {
        //     state.deleteCategoryDetails.apiMsg.status = action.meta.requestStatus;
        // });
        // builder.addCase(categoriesDeleteACTION.rejected, (state, action) => {
        //     state.deleteCategoryDetails.apiMsg.message = action.error.message;
        //     state.deleteCategoryDetails.apiMsg.status = action.meta.requestStatus;
        // });


    }

})

export const { RESET, ResetCategoryDetailsState } = categoryReducer.actions;
export default categoryReducer.reducer;