import { createSlice } from "@reduxjs/toolkit";
import { getAllHomeSliderAction, insertUpdateHomeSliderAction , HomeSliderStatusChangeACTION} from "../../actions/homePageActions/homeSliderAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllHomeSlider: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateHomeSliderDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactiveHomeSliderDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


}

const HomeSliderReducer = createSlice({
    name: "Home Slider",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetHomeSliderDetailsState(state, action) {
            state.insertUpdateHomeSliderDetails = INITIAL_STATE.insertUpdateHomeSliderDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllHomeSliderAction.fulfilled, (state, action) => {
            // console.log("getAllHomeSliderAction success", action.payload.data);
            state.getAllHomeSlider.data = action.payload.data.records;
            // console.log(state.getAllHomeSlider.data , 'given data');
            state.getAllHomeSlider.apiMsg.status = action.meta.requestStatus;
            state.getAllHomeSlider.apiMsg.message = "success";
            // console.log(state.getAllHomeSlider.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllHomeSliderAction.pending, (state, action) => {
            // console.log("getAllHomeSliderAction pending", action);
            state.getAllHomeSlider.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllHomeSliderAction.rejected, (state, action) => {
            // console.log("getAllHomeSliderAction rejected", action);
            state.getAllHomeSlider.apiMsg.message = action.error.message;
            state.getAllHomeSlider.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateHomeSliderAction.fulfilled,
            (state, action) => {

                state.insertUpdateHomeSliderDetails.data = action.payload.data.data;
                state.insertUpdateHomeSliderDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateHomeSliderDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data.data, 'reducerdata')

                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateHomeSliderAction.pending,
            (state, action) => {
                state.insertUpdateHomeSliderDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateHomeSliderAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateHomeSliderDetails.apiMsg.message = action.error.message;
                state.insertUpdateHomeSliderDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );


        // active inactive status case


        builder.addCase(HomeSliderStatusChangeACTION.fulfilled, (state, action) => {
            // console.log(action.payload, 'reducer payload');

            state.activeInactiveHomeSliderDetails.data = action.payload.data;
            state.activeInactiveHomeSliderDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveHomeSliderDetails.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });

        });

        builder.addCase(HomeSliderStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveHomeSliderDetails.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(HomeSliderStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveHomeSliderDetails.apiMsg.message = action.error.message;
            state.activeInactiveHomeSliderDetails.apiMsg.status = action.meta.requestStatus;
        });



    }

})

export const { RESET, ResetHomeSliderDetailsState } = HomeSliderReducer.actions;
export default HomeSliderReducer.reducer;