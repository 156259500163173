import { createSlice } from "@reduxjs/toolkit";
import { getAllHomeDetailAction, insertUpdateHomeDetailsAction } from "../../actions/homePageActions/homeDetailAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllHomeDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateHomeDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
       
    },

    // activeInactiveCategoryDetails: {
    //     data: {},
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // },

    // deleteCategoryDetails: {
    //     data: {},
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // }

}

const homeDetailReducer = createSlice({
    name: "HomeDetails",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        // ResetHomeDetailsState(state, action) {
        //     state.insertUpdateHomeDetails = INITIAL_STATE.insertUpdateHomeDetails;
        // },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllHomeDetailAction.fulfilled, (state, action) => {
            // console.log("getAllHomeDetailAction success", action.payload.data);
            state.getAllHomeDetails.data = action.payload.data;
            // console.log(state.getAllHomeDetails.data , 'given data');
            state.getAllHomeDetails.apiMsg.status = action.meta.requestStatus;
            state.getAllHomeDetails.apiMsg.message = "success";
            // console.log(state.getAllHomeDetails.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllHomeDetailAction.pending, (state, action) => {
            // console.log("getAllHomeDetailAction pending", action);
            state.getAllHomeDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllHomeDetailAction.rejected, (state, action) => {
            // console.log("getAllHomeDetailAction rejected", action);
            state.getAllHomeDetails.apiMsg.message = action.error.message;
            state.getAllHomeDetails.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateHomeDetailsAction.fulfilled,
            (state, action) => {

                state.insertUpdateHomeDetails.data = action.payload.data;
                state.insertUpdateHomeDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateHomeDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data, 'reducerdata')

                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateHomeDetailsAction.pending,
            (state, action) => {
                state.insertUpdateHomeDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateHomeDetailsAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateHomeDetails.apiMsg.message = action.error.message;
                state.insertUpdateHomeDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );



    }

})

// export const { RESET, ResetHomeDetailsState } = homeDetailReducer.actions;
export default homeDetailReducer.reducer;