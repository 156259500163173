import { createSlice } from "@reduxjs/toolkit";
import { getAllProductsAction , insertUpdateProductAction, productStatusChangeACTION} from "../../actions/menuActions/productActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllProducts: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateProductDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactiveProductDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    // deleteCategoryDetails: {
    //     data: {},
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // }

}

const productReducer = createSlice({
    name: "Product",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProductDetailsState(state, action) {
            state.insertUpdateProductDetails = INITIAL_STATE.insertUpdateProductDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
            // console.log("getAllProductsAction success", action.payload.data);
            state.getAllProducts.data = action.payload.data.records;
            // console.log(state.getAllProducts.data , 'given data');
            state.getAllProducts.apiMsg.status = action.meta.requestStatus;
            state.getAllProducts.apiMsg.message = "success";
            // console.log(state.getAllProducts.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllProductsAction.pending, (state, action) => {
            // console.log("getAllProductsAction pending", action);
            state.getAllProducts.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductsAction.rejected, (state, action) => {
            // console.log("getAllProductsAction rejected", action);
            state.getAllProducts.apiMsg.message = action.error.message;
            state.getAllProducts.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateProductAction.fulfilled,
            (state, action) => {

                state.insertUpdateProductDetails.data = action.payload.data.data;
                state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateProductDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data.data, 'reducerdata')

                if (!action.payload.newRecord) {
                    // let updatedRecord = state.getAllProducts.data.records.map(x => {
                    //     if (x.id == action.payload.data.data.id) {
                    //         console.log(action.payload.data, 'updated Data');
                    //         return action.payload.data.data;
                    //     }
                    //     else {
                    //         return x;
                    //     }
                    // })

                    // state.getAllProducts.data.records = updatedRecord;
                }
                else {
                    if (state.getAllProducts.data.records) {
                        state.getAllProducts.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        // state.getAllProducts.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateProductAction.pending,
            (state, action) => {
                state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateProductAction.rejected,
            (state, action) => {

                // console.log(action.error.message);
                state.insertUpdateProductDetails.apiMsg.message = action.error.message;
                state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );


        // active inactive status case


        builder.addCase(productStatusChangeACTION.fulfilled, (state, action) => {
            // console.log(action.payload, 'reducer payload');
            // state.activeInactiveProductDetails.data.records = state.activeInactiveProductDetails.data.records.map((item) => {

            //     return {
            //         ...item,
            //         active: action.payload.id === item.id ? !item.active : item.active
            //     }
            // })

            state.activeInactiveProductDetails.data = action.payload.data;
            state.activeInactiveProductDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveProductDetails.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });

        });

        builder.addCase(productStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveProductDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(productStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveProductDetails.apiMsg.message = action.error.message;
            state.activeInactiveProductDetails.apiMsg.status = action.meta.requestStatus;
        });


    }

})

export const { RESET, ResetProductDetailsState } = productReducer.actions;
export default productReducer.reducer;