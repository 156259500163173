import { createSlice } from "@reduxjs/toolkit";
import { getAllRestaurantAction, insertUpdateRestaurantAction, restaurantStatusChangeACTION } from "../../actions/homePageActions/restaurantDetailAction.js";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    Restaurants: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateRestaurantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactiveRestaurantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const RestaurantReducer = createSlice({
    name: "Restaurants",
    initialState: INITIAL_STATE,
    reducers: {
        SELECTRESTAURANTaction(state, action) {
            // console.log("SELECTRESTAURANTaction ", action);
            state.Restaurants.data = action.payload;
          },
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetstateRestaurant(state, action) {
            // state.Restaurants = INITIAL_STATE.Restaurants;
            state.insertUpdateRestaurantDetails = INITIAL_STATE.insertUpdateRestaurantDetails;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAllRestaurantAction.fulfilled, (state, action) => {
        
            // console.log("getAllRestaurantAction success", action);
            state.Restaurants.data = action.payload.data.records;
            // console.log(state.Restaurants.data , 'given data');
            state.Restaurants.apiMsg.status = action.meta.requestStatus;
            state.Restaurants.apiMsg.message = "success";
            // console.log(state.getAllMenu.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllRestaurantAction.pending, (state, action) => {
            // console.log("getAllRestaurantAction pending", action);
            state.Restaurants.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllRestaurantAction.rejected, (state, action) => {
            // console.log("getAllRestaurantAction rejected", action);
            state.Restaurants.apiMsg.message = action.error.message;
            state.Restaurants.apiMsg.status = action.meta.requestStatus;
        });


        //insertupdate reducer

        builder.addCase(
            insertUpdateRestaurantAction.fulfilled,
            (state, action) => {

                state.insertUpdateRestaurantDetails.data = action.payload.data.data;
                state.insertUpdateRestaurantDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateRestaurantDetails.apiMsg.message = action.payload.message.message;

                // console.log(action.payload.data.data, 'reducerdata')

                if (!action.payload.newRecord) {
                    // let updatedRecord = state.Restaurants.data.records.map(x => {
                    //     if (x.id == action.payload.data.data.id) {
                    //         console.log(action.payload.data, 'updated Data');
                    //         return action.payload.data.data;
                    //     }
                    //     else {
                    //         return x;
                    //     }
                    // })

                    // state.getAllCategory.data.records = updatedRecord;
                }
                else {
                    if (state.Restaurants.data.records) {
                        state.Restaurants.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        // state.Restaurants.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );

        builder.addCase(
            insertUpdateRestaurantAction.pending,
            (state, action) => {
                state.insertUpdateRestaurantDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRestaurantAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateRestaurantDetails.apiMsg.message = action.error.message;
                state.insertUpdateRestaurantDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );

        
        //active Inactive

        builder.addCase(restaurantStatusChangeACTION.fulfilled, (state, action) => {
            // console.log(action.payload, 'reducer payload');
            // state.activeInactiveRestaurantDetails.data.records = state.activeInactiveRestaurantDetails.data.records.map((item) => {

            //     return {
            //         ...item,
            //         active: action.payload.id === item.id ? !item.active : item.active
            //     }
            // })

            state.activeInactiveRestaurantDetails.data = action.payload.data;
            state.activeInactiveRestaurantDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveRestaurantDetails.apiMsg.message = action.payload.message.message;
            
            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });

        });

        builder.addCase(restaurantStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveRestaurantDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveRestaurantDetails.apiMsg.message = action.error.message;
            state.activeInactiveRestaurantDetails.apiMsg.status = action.meta.requestStatus;
        });

    }
})
export default RestaurantReducer.reducer;
export const { RESET, ResetstateRestaurant, SELECTRESTAURANTaction } = RestaurantReducer.actions;
