import { createSlice } from "@reduxjs/toolkit";
import { getAllInquiryAction, InquiryStatusChangeACTION } from "../../actions/inquiryActions/inquiryAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    getAllInquiry: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    // insertUpdateInquiryDetails: {
    //     data: {},

    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    //     newRecord: false
    // },

    activeInactiveInquiryDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const inquiryReducer = createSlice({
    name: "Inquiry",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetInquiryDetailsState(state, action) {
            state.insertUpdateInquiryDetails = INITIAL_STATE.insertUpdateInquiryDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllInquiryAction.fulfilled, (state, action) => {
            // console.log("getAllInquiryAction success", action.payload.data);
            state.getAllInquiry.data = action.payload.data.records;
            // console.log(state.getAllInquiry.data , 'given data');
            state.getAllInquiry.apiMsg.status = action.meta.requestStatus;
            state.getAllInquiry.apiMsg.message = "success";
            // console.log(state.getAllInquiry.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllInquiryAction.pending, (state, action) => {
            // console.log("getAllInquiryAction pending", action);
            state.getAllInquiry.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllInquiryAction.rejected, (state, action) => {
            // console.log("getAllInquiryAction rejected", action);
            state.getAllInquiry.apiMsg.message = action.error.message;
            state.getAllInquiry.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        // builder.addCase(
        //     insertUpdateCategoryAction.fulfilled,
        //     (state, action) => {

        //         state.insertUpdateInquiryDetails.data = action.payload.data.data;
        //         state.insertUpdateInquiryDetails.apiMsg.status = action.meta.requestStatus;
        //         state.insertUpdateInquiryDetails.apiMsg.message = action.payload.message.message;

        //         // console.log(action.payload.data.data, 'reducerdata')
        //         toast.success(action.payload.message.message, {
        //             position: 'bottom-center',
        //             toastId: "dept-error"
        //         });

        //     }
        // );

        // builder.addCase(
        //     insertUpdateCategoryAction.pending,
        //     (state, action) => {
        //         state.insertUpdateInquiryDetails.apiMsg.status = action.meta.requestStatus;
        //     }
        // );

        // builder.addCase(
        //     insertUpdateCategoryAction.rejected,
        //     (state, action) => {

        //         console.log(action.error.message);
        //         state.insertUpdateInquiryDetails.apiMsg.message = action.error.message;
        //         state.insertUpdateInquiryDetails.apiMsg.status = action.meta.requestStatus;

        //         toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        //             position: 'bottom-center',
        //             toastId: "dept-error"
        //         });
        //     }
        // );


        // active inactive status case


        builder.addCase(InquiryStatusChangeACTION.fulfilled, (state, action) => {
            state.activeInactiveInquiryDetails.data = action.payload.data;
            state.activeInactiveInquiryDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveInquiryDetails.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });
        });

        builder.addCase(InquiryStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveInquiryDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(InquiryStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveInquiryDetails.apiMsg.message = action.error.message;
            state.activeInactiveInquiryDetails.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { RESET, ResetInquiryDetailsState } = inquiryReducer.actions;
export default inquiryReducer.reducer;