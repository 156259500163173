import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
// import * as Toast from "../../../component/Toast";

export const getAllMenuAction = createAsyncThunk(
  "Menu/getAllMenuAction",

  async (data) => {  
    // let d = {
    //   restaurantID: restaurantID, 
    //   page: {
    //     pageNumber: 0,
    //     pageSize: 0
    //   },
    //   deviceType: 0
    // };

    return apiCall("menu/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // console.log("api call data", response.data);
            return {
              ...response.data,
              data: {
                ...response.data.data,
              },
            };
          } else {
            // Toast.error(response.data.message.message);
            // return Promise.reject({
            //   ...response.data.message,
            // });
          }
        }
      })
      .catch((error) => {
        // Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);



//insertUpdate


export const insertUpdateMenuAction = createAsyncThunk(
  "Menu/insertUpdateMenuAction",
  async (data) => {

    return apiCall("menu/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            // console.log("message",message);
            // console.log(response.data,'response data of update')
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const menuStatusChangeACTION = createAsyncThunk(
  "Categories/categoriesStatusChangeACTION",
  async (data) => {
    return apiCall("menu/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // Toast.success(response.data.message.message);
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            // Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        // Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);