import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import WithLoading from './components/shared/WithLoading';
import RouteLoader from './components/shared/RouteLoader';
import Layout from './components/Layout';
import PartySpaceDetail from './pages/Home/party-space-detail/PartySpaceDetail';
const RequireAuth = (props) => {
    return <> {props.active ? props.children : <Navigate to="/auth/login" replace />} </>;
};

function AppRoutes(props) {
    const LoginPage = WithLoading(React.lazy(() => import("./pages/Login/Login")));
    const DashBoardPage = WithLoading(React.lazy(()=>import("./pages/Dashboard/Dashboard")))
    const MainMenuPage = WithLoading(React.lazy(() => import("./pages/Bar/MainMenu")));
    const CategoryPage = WithLoading(React.lazy(() => import("./pages/Menu/Category/Category")));
    const ProductPage = WithLoading(React.lazy(() => import("./pages/Menu/Product/Product")));
    const HomeDetailsPage = WithLoading(React.lazy(() => import("./pages/Home/home-detail/Home.detail")));
    const MenuDetailsPage = WithLoading(React.lazy(() => import("./pages/Home/menu-detail/Menu.detail")));
    const HomeSliderPage = WithLoading(React.lazy(() => import("./pages/Home/home-slider/HomeSilder")));
    const PartySpaceDetailPage = WithLoading(React.lazy(() => import("./pages/Home/party-space-detail/PartySpaceDetail")));
    const HappyHourMenuPage = WithLoading(React.lazy(() => import("./pages/Home/happy-hour-menu/HappyHourMenu")));
    const StudentsNightPage = WithLoading(React.lazy(() => import("./pages/Bar/StudentsNight")));
    const HappyHoursPage = WithLoading(React.lazy(() => import("./pages/Bar/HappyHours")));
    const MenuPage = WithLoading(React.lazy(() => import("./pages/Menu/Menu/Menu")));
    const HeadOfficeUserPage = WithLoading(React.lazy(() => import("./pages/OwnerSettings/HeadOfficeUsers/HeadOfficeUsers")));
    const HomePage = WithLoading(React.lazy(() => import("./pages/Home/Home")));
    const InquiryPage = WithLoading(React.lazy(() => import("./pages/Inquiry/Inquiry")));
    const RestaurantsDetailsPage = WithLoading(React.lazy(() => import("./pages/Home/restaurants-detail/RestaurantsDetails")));
    
    const protectedLayout = (
        <>
            {
                <>

                    <RequireAuth active={props.active}>
                        <Layout />

                    </RequireAuth>

                </>

            }
        </>
    );
    return (

        <Routes>
            <Route path="/" element={protectedLayout}>
                <Route index element={<DashBoardPage />} />
            </Route>
            <Route path="/dashboard" element={protectedLayout}>
                <Route index element={<DashBoardPage />} />
            </Route>
            
            <Route path="/Home" element={protectedLayout}>
            <Route path="HomeDetails" element={<HomeDetailsPage />} />
                <Route path="MenuDetails" element={<MenuDetailsPage />} />
                <Route path="HomeSlider" element={<HomeSliderPage />} />
                <Route path="HappyHourMenu" element={<HappyHourMenuPage />} />
                <Route path="PartySpaceDetail" element={<PartySpaceDetailPage />} />
                <Route path="Restaurants" element={<RestaurantsDetailsPage />} />
            </Route>

            <Route path="/Menu" element={protectedLayout}>
                <Route path="menu" element={<MenuPage />} />
                <Route path="category" element={<CategoryPage />} />
                <Route path="product" element={<ProductPage/>} />


            </Route>
            <Route path="/Bar" element={protectedLayout}>
                <Route path='MainMenu' element={<MainMenuPage />} />
                <Route path='StudentsNight' element={<StudentsNightPage />} />
                <Route path='HappyHours' element={<HappyHoursPage />} />
            </Route>
            <Route path="/ownerSettings" element={protectedLayout}>
                <Route path='HeadofficeUsers' element={<HeadOfficeUserPage />} />
            </Route>

            <Route path="/inquiry" element={protectedLayout}>
                <Route index element={<InquiryPage />} />
            </Route>


        </Routes>

    )
}

export default AppRoutes